import { SkeletonInProduction } from '@components/SkeletonInProduction';
import { useOrderStore } from '@stores/useOrderStore';
import { FlatList, View } from 'native-base';
import React from 'react';

export function InProgress() {
  const items = useOrderStore(s => s.inProgress);

  return (
    <View flex="1" padding="12">
      <FlatList
        data={items}
        renderItem={({ item }) => <SkeletonInProduction item={item} />}
      />
    </View>
  );
}
