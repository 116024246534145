import { MotiView, useDynamicAnimation } from 'moti';
import {
  Center,
  Skeleton,
  VStack,
  Text,
  Button,
  Flex,
  Stack,
  HStack,
  Box,
  Divider,
  Heading,
} from 'native-base';
import { memo, useState } from 'react';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';

import { OrderAnimation } from './OrderAnimation';
import { SkeletonHeader } from './SkeletonHeader';
import { IOrder } from '@interfaces/orders/IOrder';
import { useUpdateOrder } from '@hooks/orders/useUpdateOrder';
import { TableOrders } from './TableOrders';

interface Props {
  item: IOrder;
}

function SkeletonOrderMemo({ item }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const onUpdate = useUpdateOrder();
  const loading = onUpdate.isLoading;

  const animation = useDynamicAnimation(() => ({
    transform: [{ rotate: '0deg' }],
  }));

  function handleOpen() {
    animation.animateTo({
      transform: [{ rotate: isOpen ? '0deg' : '180deg' }],
    });
    setIsOpen(!isOpen);
    console.log('handleOpen')
  }

  async function handleUpdate(status: '02' | '03') {
    const ids = item.orders.map(order => order.id);
    onUpdate.mutateAsync({ ids, status, oldStatus: '01' });
  }

  if (item.orders.length === 1) {
    const order = item.orders[0];

    return (
      <Center paddingX="5" mb="5" key={item.id}>
        <VStack w="100%" space="4">
          {/* header */}
          <SkeletonHeader
            isLoading={!loading}
            name={`${order.client?.name} - (${order.order})`}
            textRight={'R$ ' + item.total}
          />
          {/* body */}
          {!loading && (
            <OrderAnimation
              isOpen={isOpen}
              openComponent={
                <Stack space="3" paddingX="4">
                  <TableOrders purchases={order.purchases} />
                </Stack>
              }
            />
          )}
          {/* footer */}
          <Skeleton endColor="dark.200" rounded="lg" isLoaded={!loading}>
            <Flex direction="row" justify="space-between">
              <Button
                variant="unstyled"
                onPress={handleOpen}
                leftIcon={
                  <MotiView
                    transition={{ type: 'timing', duration: 600 }}
                    state={animation}>
                    <Icon
                      name="chevron-double-down"
                      color="#E2A900"
                      size={20}
                    />
                  </MotiView>
                }
                _text={{
                  fontSize: '15px',
                  fontWeight: 500,
                  color: 'amber.500',
                  lineHeight: '17px',
                }}>
                {isOpen ? 'Ver menos' : 'Ver mais'}
              </Button>
              <HStack justifyContent="flex-end" space="2">
                <Button
                  onPress={() => handleUpdate('03')}
                  variant="unstyled"
                  _text={{
                    fontWeight: 300,
                    color: 'red.600',
                  }}>
                  Cancelar
                </Button>
                <Button
                  bg="success.600"
                  colorScheme="success"
                  rounded="lg"
                  onPress={() => handleUpdate('02')}
                  _text={{
                    fontWeight: 500,
                    color: 'white',
                  }}>
                  Confirmar Pedido
                </Button>
              </HStack>
            </Flex>
          </Skeleton>
        </VStack>
      </Center>
    );
  }

  return (
    <Center paddingX="5" mb="5" key={item.id}>
      <VStack w="100%" space="4">
        {/* header */}
        <SkeletonHeader
          isLoading={!loading}
          name={item.group || ''}
          textRight={'R$ ' + item.total}
        />
        {/* body */}
        {!loading && (
          <OrderAnimation
            isOpen={isOpen}
            openComponent={
              <Stack space="3" paddingX="4">
                {item.orders.map(order => (
                  <Box key={order.id}>
                    <Flex direction="row" justify="space-between" paddingX="2">
                      <Heading fontSize="18px" lineHeight="21" color="black">
                        {order.client?.name || order.name}
                      </Heading>
                      <Text fontSize="14px" lineHeight="16" color="black">
                        {order.order}
                      </Text>
                    </Flex>
                    <Divider bg="black" />
                    <TableOrders purchases={order.purchases} />
                    <Text color="black" textAlign="right">
                      valor total: R${order.total}
                    </Text>
                  </Box>
                ))}
              </Stack>
            }
          />
        )}
        {/* footer */}
        <Skeleton endColor="dark.200" rounded="lg" isLoaded={!loading}>
          <Flex direction="row" justify="space-between">
            <Button
              variant="unstyled"
              onPress={handleOpen}
              leftIcon={
                <MotiView
                  transition={{ type: 'timing', duration: 600 }}
                  state={animation}>
                  <Icon name="chevron-double-down" color="#E2A900" size={20} />
                </MotiView>
              }
              _text={{
                fontSize: '15px',
                fontWeight: 500,
                color: 'amber.500',
                lineHeight: '17px',
              }}>
              {isOpen ? 'Ver menos' : 'Ver mais'}
            </Button>
            <HStack justifyContent="flex-end" space="2">
              <Button
                onPress={() => handleUpdate('03')}
                variant="unstyled"
                _text={{
                  fontWeight: 300,
                  color: 'red.600',
                }}>
                Cancelar
              </Button>
              <Button
                bg="success.600"
                colorScheme="success"
                rounded="lg"
                onPress={() => handleUpdate('02')}
                _text={{
                  fontWeight: 500,
                  color: 'white',
                }}>
                Confirmar Pedido
              </Button>
            </HStack>
          </Flex>
        </Skeleton>
      </VStack>
    </Center>
  );
}

export const SkeletonOrder = memo(SkeletonOrderMemo);
