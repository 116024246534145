import { io, Socket } from 'socket.io-client';

import { authStore } from '@stores/useAuthStore';

import { time5Minutes } from '@utils/times';

let socketRef: Socket;

export function socketIo() {
  if (socketRef) {
    return socketRef;
  }
  const url = __DEV__
    ? 'ws://192.168.1.62:3001'
    : 'wss://armalite.ideploycode.com.br';

  const { token, companyId } = authStore.getState();

  const socket = io(url, {
    auth: { companyId, app: 'KDS' },
    extraHeaders: { Authorization: token as string },
  });

  socket.on('connect_error', async err => {
    socket.disconnect();
    console.log('connect_error:', err);

    setTimeout(() => {
      console.log('reconnecting');
      socket.connect();
    }, time5Minutes * 3);
  });

  socket.on('sign-out', () => {
    socket.disconnect();
  });

  socketRef = socket;

  return socket;
}
