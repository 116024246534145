import { View, Center, Image } from 'native-base';
import React, { ReactNode } from 'react';
import { SvgUri } from 'react-native-svg';
// import Logo from '@assets/svg/logo.svg';

interface IProps {
  children: ReactNode;
}

export function Layout({ children }: IProps) {
  return (
    <View flex="1">
      <Image
        source={require('@assets/images/background.png')}
        alt="logo"
        width="100%"
        height="100%"
        resizeMode="cover"
        position="absolute"
      />
      <View flex="1">{children}</View>
      <Center bg="amber.500" py='5'>
        <Image
          source={require('@assets/svg/logo.svg')}
          alt="logo"
          height='40px'
          width='100%'
          resizeMode="contain"
        />
      </Center>
    </View>
  );
}
