import { useUpdateOrder } from '@hooks/orders/useUpdateOrder';
import { IOrder } from '@interfaces/orders/IOrder';
import { MotiView, useDynamicAnimation } from 'moti';
import { Center, Skeleton, VStack, Button, Stack } from 'native-base';
import { memo, useState } from 'react';
import Icon from '@expo/vector-icons/MaterialCommunityIcons';

import { OrderAnimation } from './OrderAnimation';
import { Orders } from './Orders';
import { SkeletonHeader } from './SkeletonHeader';
import { TableOrders } from './TableOrders';

interface Props {
  item: IOrder;
}

function SkeletonFinishedMemo({ item }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const onUpdate = useUpdateOrder();
  const loading = onUpdate.isLoading;

  const animation = useDynamicAnimation(() => ({
    transform: [{ rotate: '0deg' }],
  }));

  function handleOpen() {
    animation.animateTo({
      transform: [{ rotate: isOpen ? '0deg' : '180deg' }],
    });
    setIsOpen(!isOpen);
  }

  if (item.orders.length === 1) {
    return (
      <Center paddingX="5" mb="5" key={item.id}>
        <VStack w="100%" space="4">
          {/* header */}
          <SkeletonHeader
            isLoading={!loading}
            name={item.orders[0]?.client?.name as string}
            textRight={'R$ ' + item.total}
          />
          {/* body */}
          {!loading && (
            <OrderAnimation
              isOpen={isOpen}
              openComponent={
                <Stack space="3" paddingX="4">
                  <TableOrders purchases={item.orders[0].purchases} />
                </Stack>
              }
            />
          )}
          {/* footer */}
          <Skeleton endColor="dark.200" rounded="lg" isLoaded={!loading}>
            <Button
              variant="solid"
              colorScheme="amber"
              onPress={handleOpen}
              borderTopRadius="0"
              bg="amber.500"
              borderBottomRadius="10px"
              leftIcon={
                <MotiView
                  transition={{ type: 'timing', duration: 600 }}
                  state={animation}>
                  <Icon name="chevron-double-down" color="#fff" size={20} />
                </MotiView>
              }
              _text={{
                fontSize: '15px',
                fontWeight: 500,
                lineHeight: '17px',
              }}>
              {isOpen ? 'Menos' : 'Detalhes'}
            </Button>
          </Skeleton>
        </VStack>
      </Center>
    );
  }

  return (
    <Center paddingX="5" mb="5" key={item.id}>
      <VStack w="100%" space="4">
        {/* header */}
        <SkeletonHeader
          isLoading={!loading}
          name={item.group || ''}
          textRight={'R$ ' + item.total}
        />
        {/* body */}
        {!loading && (
          <OrderAnimation
            isOpen={isOpen}
            openComponent={<Orders orders={item.orders} />}
          />
        )}
        {/* footer */}
        <Skeleton endColor="dark.200" rounded="lg" isLoaded={!loading}>
          <Button
            variant="solid"
            colorScheme="amber"
            onPress={handleOpen}
            borderTopRadius="0"
            bg="amber.500"
            borderBottomRadius="10px"
            leftIcon={
              <MotiView
                transition={{ type: 'timing', duration: 600 }}
                state={animation}>
                <Icon name="chevron-double-down" color="#fff" size={20} />
              </MotiView>
            }
            _text={{
              fontSize: '15px',
              fontWeight: 500,
              lineHeight: '17px',
            }}>
            {isOpen ? 'Menos' : 'Detalhes'}
          </Button>
        </Skeleton>
      </VStack>
    </Center>
  );
}

export const SkeletonFinished = memo(SkeletonFinishedMemo);
