import { width } from '@utils/dimensions';
import { Badge, Button, Box } from 'native-base';
import { IButtonProps } from 'native-base/lib/typescript/components/primitives/Button/types';

interface Props extends IButtonProps {
  count?: number;
  title: string;
}

export function ButtonNotification({ count, title, ...rest }: Props) {
  return (
    <Box>
      <Button
        _text={{ fontSize: 20, fontWeight: 300 }}
        w={width * 0.28}
        h="12"
        borderRadius="7px"
        // borderBottomRadius="7px"
        _disabled={{
          bg: 'amber.500',
          opacity: 1,
          _text: { fontWeight: 700, color: 'white' },
        }}
        colorScheme="light"
        bg="gray.700"
        {...rest}>
        {title}
      </Button>
      {!!count && (
        <Badge
          _text={{
            fontSize: 20,
            lineHeight: 22,
            fontWeight: 400,
          }}
          bg="gray.400"
          rounded="full"
          size="10"
          variant="solid"
          alignItems={'center'}
          justifyContent={'center'}
          position="absolute"
          top="-8"
          right="-10">
          {count > 50 ? '!!' : count}
        </Badge>
      )}
    </Box>
  );
}
