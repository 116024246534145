import { Text, Flex, Box, Heading, Divider, Stack } from 'native-base';
import { TableOrders } from './TableOrders';

type IProps = {
  orders: any[];
};

export function Orders({ orders }: IProps) {
  return (
    <Stack space="3" paddingX="4">
      {orders.map(order => (
        <Box key={order.id}>
          <Flex direction="row" justify="space-between" paddingX="2">
            <Heading fontSize="18px" lineHeight="21" color="black">
              {order.client?.name || order.name}
            </Heading>
            <Text fontSize="14px" lineHeight="16" color="black">
              {order.order}
            </Text>
          </Flex>
          <Divider bg="black" />
          <TableOrders purchases={order.purchases} />
          <Text color="black" textAlign="right">
            valor total: R${order.total}
          </Text>
        </Box>
      ))}
    </Stack>
  );
}
