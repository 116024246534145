import React from 'react';

import { SelectCompany } from '@screens/auth/SelectCompany';

import { useAuthStore } from '@stores/useAuthStore';
import { SingIn } from '@screens/auth/SingIn/SingIn';

export function AuthRoutes() {
  const isCompany = useAuthStore(s => s.companyId);

  return isCompany ? <SingIn /> : <SelectCompany />;
}
