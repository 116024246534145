import { SkeletonOrder } from '@components/Skeleton';
import { useOrderStore } from '@stores/useOrderStore';
import { FlatList, View } from 'native-base';
import React from 'react';

export function NewOrder() {
  const items = useOrderStore(s => s.newOrder);

  console.log(items.length);

  return (
    <View flex="1" padding="12">
      <FlatList
        data={items}
        renderItem={({ item }) => <SkeletonOrder item={item} />}
        keyExtractor={item => item.id.toFixed()}
        showsVerticalScrollIndicator={false}
      />
    </View>
  );
}
