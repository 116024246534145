import { extendTheme } from 'native-base';

export const theme = extendTheme<any>({
  fontConfig: {
    Paralucent: {
      100: { normal: 'Paralucent-Thin' },
      200: { normal: 'Paralucent-ExtraLight' },
      300: { normal: 'Paralucent-Light' },
      500: { normal: 'Paralucent-Medium' },
      600: { normal: 'Paralucent-DemiBold' },
      700: { normal: 'Paralucent-Bold' },
      800: { normal: 'Paralucent-Bold' },
      900: { normal: 'Paralucent-Heavy' },
    },
  },
  fonts: {
    heading: 'Paralucent',
    body: 'Paralucent',
    mono: 'Paralucent',
  },
  colors: {
    gray: {
      200: '#E5E5E5',
      300: '#F3F3F3',
      500: '#C4C4C4',
      700: '#515151',
      800: '#595959',
      900: '#292929',
    },
    red: {
      600: '#D43A3F',
    },
    dark: {
      50: '#18181b',
      100: '#1E1E1E',
      500: '#A9A9A9',
    },
    success: {
      600: '#62A851',
    },
    amber: {
      500: '#E2A900',
    },
  },
  components: {
    Input: {
      baseStyle: {
        autoCapitalize: 'none',
        fontFamily: 'body',
      },
      defaultProps: {
        padding: '3',
        paddingLeft: '5',
        _focus: { borderColor: '#E2A900' },
        // variant: 'underlined',
        color: '#E2A900',
        borderRadius: '8',
        placeholderTextColor: '#E2A900',
        fontSize: '18',
        borderWidth: '3',
        borderColor: '#E2A900',
      },
    },
    Button: {
      baseStyle: () => ({
        borderRadius: '6',
      }),
      defaultProps: {
        size: 'lg',
        _text: { fontWeight: 500, color: '#F3F3F3' },
      },
    },
    VStack: {
      baseStyle: ({ colorMode }: Record<string, any>) => ({
        bg: colorMode === 'dark' ? 'dark.100' : 'white',
        rounded: 'xl',
        padding: '4',
      }),
    },
    Divider: {
      baseStyle: {
        bg: '#D43A3F',
        mt: '6px',
      },
    },
    ModalContent: {
      baseStyle: ({ colorMode }: Record<string, any>) => ({
        bg: colorMode === 'dark' ? 'dark.100' : 'trueGray.100',
      }),
    },
    ModalFooter: {
      baseStyle: ({ colorMode }: Record<string, any>) => ({
        bg: colorMode === 'dark' ? 'dark.100' : 'trueGray.100',
      }),
    },
    Text: {
      baseStyle: {
        fontSize: '15',
        fontFamily: 'body',
      },
      defaultProps: {
        fontWeight: 500,
        color: 'white',
        textAlign: 'center',
      },
    },
    Heading: {
      defaultProps: { color: 'white' },
    },
  },
});
