import create from 'zustand';
import createVilania from 'zustand/vanilla';
import { persist } from 'zustand/middleware';
import AsyncStorage from '@react-native-async-storage/async-storage';

type IAuth = {
  token: string | undefined;
  refreshToken: string | undefined;
  nameUnique: string | undefined;
  companyId: string | undefined;
  onCompany(nameUnique: string, companyId: string): void;
  onToken(token: string, refreshToken: string): void;
  onOutLogin(): void;
};

export const authStore = createVilania(
  persist<IAuth>(
    set => ({
      nameUnique: undefined,
      token: undefined,
      refreshToken: undefined,
      companyId: undefined,
      onCompany(nameUnique, companyId) {
        set({ nameUnique, companyId });
      },
      onToken(token, refreshToken) {
        set({ token, refreshToken });
      },
      onOutLogin() {
        set({
          token: undefined,
          refreshToken: undefined,
          nameUnique: undefined,
          companyId: undefined,
        });
      },
    }),
    {
      name: 'auth-store',
      getStorage: () => AsyncStorage,
    },
  ),
);

export const useAuthStore = create(authStore);
