import { ISuccess } from '@interfaces/IResponse';
import { api } from '@services/api';
import { AppError } from '@services/errors/AppErro';
import { queryClient } from '@services/queryClient';
import { useAuthStore } from '@stores/useAuthStore';
import { useMutation } from '@tanstack/react-query';
import { showToast } from '@utils/showToast';

interface IProps {
  email: string;
  password: string;
}

interface IResponse {
  token: string;
  refresh_token: string;
}

export function useSingIn() {
  const { nameUnique, onToken } = useAuthStore();

  return useMutation(
    async (values: IProps) => {
      const { data } = await api.post<ISuccess<IResponse>>('/auth/login', {
        ...values,
        nameUnique,
      });

      return data.payload;
    },
    {
      onSuccess({ token, refresh_token }) {
        onToken(token, refresh_token);
        queryClient.invalidateQueries();
      },
      onError(err: AppError) {
        console.log(err);
        showToast({
          title: 'Erro ao fazer login!',
          message: 'Verifique seu e-mail e senha',
          type: 'error',
        });
      },
    },
  );
}
