import asyncStorage from '@react-native-async-storage/async-storage';
import { authStore } from '@stores/useAuthStore';
import axios, { AxiosError } from 'axios';

import { AppError } from './errors/AppErro';

let isRefreshToken = false;
let failedRequestsQueue: any[] = [];

const api = axios.create({
  baseURL: __DEV__
    ? 'http://192.168.1.62:3001/v1/'
    : 'https://armalite.ideploycode.com.br/v1/',
});

api.interceptors.response.use(
  (response: any) => response,
  async (error: AxiosError<any>) => {
    if (error.response?.status === 401) {
      console.log('Refresh token error');
      const singOut = authStore.getState().onOutLogin;
      singOut();
      console.log('Refresh token error 2');

      if (error.response.data?.message === 'Token expired') {
        const originalConfig = error.config;
        if (!isRefreshToken) {
          isRefreshToken = true;

          const refresh = await asyncStorage.getItem('@easyLink:RefreshToken');

          await api
            .post(`/auth/refresh-token?refresh=${refresh}`)
            .then(async ({ data }) => {
              await asyncStorage.setItem('@easyLink:Token', data.token);
              await asyncStorage.setItem(
                '@easyLink:RefreshToken',
                data.refresh_token,
              );
              api.defaults.headers.common.Authorization = `Bearer ${data.token}`;

              failedRequestsQueue.forEach((request: any) =>
                request.onSuccess(data.token),
              );
              failedRequestsQueue = [];
            })
            .catch(() => {
              failedRequestsQueue.forEach(request => request.onFailure());
              failedRequestsQueue = [];
              // signOut
            })
            .finally(() => {
              isRefreshToken = false;
            });
        }

        return new Promise((resolve, reject) => {
          failedRequestsQueue.push({
            onSuccess: (token: string) => {
              originalConfig.headers = {
                ...originalConfig.headers,
                Authorization: `Bearer ${token}`,
              };
              resolve(api(originalConfig));
            },
            onFailure: (err: AxiosError) => {
              reject(err);
            },
          });
        });
      }

      // signOut();
      return Promise.reject(new AppError(error?.response?.data));
    }

    return Promise.reject(new AppError(error?.response?.data));
  },
);

api.interceptors.request.use(async (request: any) => {
  const status = await asyncStorage.getItem('auth-store');

  if (status) {
    const {
      state: { token },
    } = JSON.parse(status);
    if (token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      request.headers.Authorization = `Bearer ${token}`;
    }
  }

  return request;
});

export { api };
