import { useQuery } from '@tanstack/react-query';
import { api } from '@services/api';

import { IError, ISuccess } from '@interfaces/IResponse';
import { authStore } from '@stores/useAuthStore';
import { IOrder } from '@interfaces/orders/IOrder';
import { toMoney } from 'vanilla-masker';

async function callApi(status: string, limit?: number): Promise<IOrder[]> {
  const { data } = await api.get<ISuccess<IOrder[]>>(
    `/${authStore.getState().companyId}/order-group`,
    { params: { status, limit } },
  );
  const items = data.payload;



  return items.map(i => {
    const total = i.orders.reduce((acc, o) => acc + Number(o.total), 0);

    return {
      ...i,
      total: toMoney(total),
      orders: i.orders.map(o => ({
        ...o,
        total: toMoney(o.total),
        purchases: o.purchases.map(p => ({
          ...p,
          price: toMoney(p.price),
          total: toMoney(p.total),
        })),
      })),
    };
  });
}

export function useOrders(status: string, limit?: number) {
  return useQuery<IOrder[], IError>(['orders', status, limit],
    () => callApi(status, limit),
    {
      //  staleTime: 1000 * 3,
    }
  );
}
