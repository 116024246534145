import { api } from '@services/api';
import { AppError } from '@services/errors/AppErro';
import { queryClient } from '@services/queryClient';
import { authStore } from '@stores/useAuthStore';
import { useMutation } from '@tanstack/react-query';
import { showToast } from '@utils/showToast';

interface IProps {
  ids: string[];
  status: string;
  oldStatus: string;
}

export function useUpdateOrder() {
  return useMutation(
    async ({ ids, status }: IProps) => {
      await api.patch(`/${authStore.getState().companyId}/order`, {
        status,
        ids,
      });
    },
    {
      onSuccess(_, { status, oldStatus }) {
        queryClient.invalidateQueries(['orders', status]);
        queryClient.invalidateQueries(['orders', oldStatus]);
      },
      onError(err: AppError) {
        console.log(err);
        showToast({
          title: 'Erro ao atualizar pedido!',
          message: 'Tente novamente',
          type: 'error',
        });
      },
    },
  );
}
