import { useAuthStore } from '@stores/useAuthStore';
import React from 'react';
import { AppRoutes } from './app.routes';

import { AuthRoutes } from './auth.routes';

export function Routes() {
  const isSigned = useAuthStore(s => s.token);

  return isSigned ? <AppRoutes key="app" /> : <AuthRoutes key="auth" />;
}
