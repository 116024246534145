import React, { useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { AppParamList } from './types';
import { InProgress } from '@screens/InProgress';
import { NewOrder } from '@screens/NewOrder';
import { Finished } from '@screens/Finished';
import { Menu } from '@components/menu';
import { socketIo } from '@services/io';
import { useOrderStore } from '@stores/useOrderStore';
import { useOrders } from '@hooks/orders/useOrders';
import { queryClient } from '@services/queryClient';
import { useAllInfos } from '@hooks/companies/useAllInfo';

const AppTab = createMaterialTopTabNavigator<AppParamList>();

export function AppRoutes() {
  const { setFS, setIP, setNO } = useOrderStore();
  useAllInfos();

  const { data: dataNO } = useOrders('01');
  const { data: dataIP } = useOrders('02');
  const { data: dataFS } = useOrders('03', 24);

  useEffect(() => {
    const socket = socketIo();

    socket.on('order', (item: any) => {
      queryClient.invalidateQueries(['orders', item.type]);
    });
  }, []);

  useEffect(() => {
    if (dataNO) setNO(dataNO);
  }, [dataNO, setNO]);
  useEffect(() => {
    if (dataIP) setIP(dataIP);
  }, [dataIP, setIP]);
  useEffect(() => {
    if (dataFS) setFS(dataFS);
  }, [dataFS, setFS]);

  return (
    <NavigationContainer>
      <AppTab.Navigator
        initialRouteName="NewOrder"
        sceneContainerStyle={{ backgroundColor: 'transparent' }}
        tabBar={({ navigation, state }) => (
          <Menu
            index={state.index}
            navigation={navigation}
            newOrder={dataNO?.length}
            inProgress={dataIP?.length}
          />
        )}>
        <AppTab.Screen name="NewOrder" component={NewOrder} />
        <AppTab.Screen name="InProgress" component={InProgress} />
        <AppTab.Screen name="Finished" component={Finished} />
      </AppTab.Navigator>
    </NavigationContainer>
  );
}
