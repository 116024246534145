import { useQuery } from '@tanstack/react-query';

import { api } from '@services/api';

import { IAllInfos } from '@interfaces/configs/IAllInfos';
import { IError, ISuccess } from '@interfaces/IResponse';
import { authStore } from '@stores/useAuthStore';

export async function findAllInfos(): Promise<IAllInfos> {
  if (!api.defaults.headers.common.Authorization) {
    throw new Error('Authorization is not defined');
  }

  const { data } = await api.get<ISuccess<IAllInfos>>('/me');

  if (data.payload?.user?.type !== 'KDS') {
    authStore.getState().onOutLogin();
    throw new Error('User is not KDS');
  }

  return data.payload;
}

export function useAllInfos() {
  return useQuery<IAllInfos, IError>(['All-Info', 1], () => findAllInfos(), {
    staleTime: 1000 * 60 * 10,
  });
}
