import { MaterialTopTabNavigationEventMap } from '@react-navigation/material-top-tabs';
import { NavigationHelpers, ParamListBase } from '@react-navigation/native';
import { Box, HStack, Icon } from 'native-base';
import FontAwesome5 from '@expo/vector-icons/FontAwesome5';

import { ButtonNotification } from './ButtonNotification';

interface Props {
  index: number;
  navigation: NavigationHelpers<
    ParamListBase,
    MaterialTopTabNavigationEventMap
  >;
  newOrder?: number;
  inProgress?: number;
}

export function Menu({ index, navigation, inProgress, newOrder }: Props) {
  return (
    <Box bg="transparent">
      <HStack
        w="100%"
        space="8"
        h="16"
        justifyContent="center"
        alignItems="flex-end">
        <ButtonNotification
          count={newOrder}
          title="Pedidos"
          onPress={() => navigation.navigate('NewOrder')}
          isDisabled={index === 0}
          leftIcon={<Icon as={FontAwesome5} name="shopping-basket" />}
        />
        <ButtonNotification
          count={inProgress}
          title="Em Produção"
          onPress={() => navigation.navigate('InProgress')}
          isDisabled={index === 1}
          leftIcon={<Icon as={FontAwesome5} name="bullseye" />}
        />
        <ButtonNotification
          title="Finalizados"
          onPress={() => navigation.navigate('Finished')}
          isDisabled={index === 2}
          leftIcon={<Icon as={FontAwesome5} name="clipboard-check" />}
        />
      </HStack>
    </Box>
  );
}
