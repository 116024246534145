import { SkeletonFinished } from '@components/SkeletonFinished';
import { useOrderStore } from '@stores/useOrderStore';
import { FlatList, View } from 'native-base';
import React from 'react';

export function Finished() {
  const items = useOrderStore(s => s.finished);

  return (
    <View flex="1" padding="12">
      <FlatList
        data={items}
        renderItem={({ item }) => <SkeletonFinished item={item} />}
      />
    </View>
  );
}
