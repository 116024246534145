import { Text, Flex, Stack } from 'native-base';

type IProps = {
  purchases: any[];
};

export function TableOrders({ purchases }: IProps) {
  return (
    <>
      <Flex w="100%" direction="row" align="center">
        <Text fontWeight={700} color="gray.800">
          Nome
        </Text>
        <Text fontWeight={700} color="gray.800" ml="auto" w="5%">
          valor
        </Text>
        <Text fontWeight={700} color="gray.800" ml="5" w="10%">
          valor
        </Text>
        <Text fontWeight={700} color="gray.800" ml="8" w="10%">
          total
        </Text>
      </Flex>
      <Stack space="1.5">
        {purchases.map((obj, index) => {
          return (
            <Stack space="1" key={index}>
              <Flex direction="row" align="center">
                <Text color="black">{obj.name}</Text>
                <Text color="black" ml="auto" w="5%">
                  {obj.quantity}
                </Text>
                <Text color="black" ml="5" w="10%">
                  {obj.price}
                </Text>
                <Text color="black" ml="5" w="10%">
                  R${obj.total}
                </Text>
              </Flex>
            </Stack>
          );
        })}
      </Stack>
    </>
  );
}
