import { useQuery } from '@tanstack/react-query';
import { api } from '@services/api';

import { IError, ISuccess } from '@interfaces/IResponse';
import { IPagination } from '@interfaces/pagination';

interface IProps {
  name: string;
  nameUnique: string;
  id: string;
}

interface IQuery {
  name: string;
}

async function ListCompanies(params: IQuery): Promise<IPagination<IProps>> {
  if (!params.name) throw new Error('Name is required');

  const { data } = await api.get<ISuccess<IPagination<IProps>>>('/company', {
    params,
  });

  return data.payload;
}

export function useCompanies(params: IQuery) {
  return useQuery<IPagination<IProps>, IError>(
    ['companies', params],
    () => ListCompanies(params),
    { staleTime: 1000 * 3 },
  );
}
