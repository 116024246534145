import { Heading, Skeleton, Text, Flex, Divider } from 'native-base';

interface Props {
  isLoading: boolean;
  name: string;
  textRight: string;
}

export function SkeletonHeader({ isLoading, name, textRight }: Props) {
  return (
    <Skeleton.Text endColor="dark.200" isLoaded={isLoading}>
      <Flex direction="row" justify="space-between" paddingX="2">
        <Heading fontSize="18px" lineHeight="21" color="amber.500">
          {name}
        </Heading>
        <Text fontSize="14px" lineHeight="16" color="amber.500">
          {textRight}
        </Text>
      </Flex>
      <Divider bg="amber.500" />
    </Skeleton.Text>
  );
}
