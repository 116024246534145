import { MotiView, useDynamicAnimation } from 'moti';
import { Stack, } from 'native-base';
import { memo, ReactNode } from 'react';

interface Props {
  isOpen: boolean;
  openComponent: ReactNode;
}

function OrderAnimationMemo({ isOpen, openComponent }: Props) {
  const animationHeight = useDynamicAnimation(() => ({
    height: 48,
  }));


  return (
    <MotiView
      state={animationHeight}
      transition={{ type: 'timing', duration: 600 }}>
      {!isOpen ? (
        <MotiView
          from={{ opacity: 0, scaleY: 0 }}
          animate={{ opacity: 1, scaleY: 1 }}
          transition={{ type: 'timing', duration: 600, delay: 200 }}
          onLayout={event => {
            const { height } = event.nativeEvent.layout;
            animationHeight.animateTo({ height });
          }}
          key="closed">
          <Stack space="2" paddingX="2" minH="1" />
        </MotiView>
      ) : (
        <MotiView
          from={{ opacity: 64, scaleY: 0 }}
          animate={{ opacity: 1, scaleY: 1 }}
          transition={{ type: 'timing', duration: 600, delay: 200 }}
          onLayout={event => {
            const { height } = event.nativeEvent.layout;
            animationHeight.animateTo({ height });
          }}
          key="open">
          {openComponent}
        </MotiView>
      )}
    </MotiView>
  );
}

export const OrderAnimation = memo(OrderAnimationMemo);
