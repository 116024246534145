import { Layout } from '@components/Layout';
import { queryClient } from '@services/queryClient';
import { QueryClientProvider } from '@tanstack/react-query';
import { NativeBaseProvider } from 'native-base';
import React, { useEffect } from 'react';
import { LogBox } from 'react-native';
import { SafeAreaProvider } from 'react-native-safe-area-context';


import { Routes } from './routers/index.routes';
import { theme } from './themes';

// import 'react-native-reanimated'
// import 'react-native-gesture-handler'

function App() {
  useEffect(() => {
    LogBox.ignoreAllLogs();
  }, []);

  return (
    <SafeAreaProvider>
      <QueryClientProvider client={queryClient}>
        <NativeBaseProvider theme={theme}>
          <Layout>
            <Routes />
          </Layout>
        </NativeBaseProvider>
      </QueryClientProvider>
    </SafeAreaProvider>
  );
}


export default App