import {
  Stack,
  Button,
  KeyboardAvoidingView,
  Input,
  Flex,
  ScrollView,
  View,
  Text,
  Heading,
} from 'native-base';
import React, { useRef, useState } from 'react';

import useDebounce from '@utils/useDebounce';
import { useSingIn } from '@hooks/auth/useSingIn';
import { Pressable } from 'react-native';
import { useAuthStore } from '@stores/useAuthStore';

export function SingIn() {
  const [email, sendEmail] = useState('');
  const [password, sendPassword] = useState('');

  const emailDebounce = useDebounce(sendEmail);
  const passwordDebounce = useDebounce(sendPassword);

  const passwordRef = useRef<any>(null);

  const { nameUnique, onOutLogin } = useAuthStore();

  const onLogin = useSingIn();

  const emailExp =
    /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,4})$/;

  function validate() {
    return emailExp.test(email) && password.length > 1;
  }

  async function handleSubmit() {
    onLogin.mutateAsync({ email, password });
  }

  return (
    <View>
      <ScrollView showsVerticalScrollIndicator={false}>
        <KeyboardAvoidingView>
          <Flex direction="row" justifyContent="flex-end">
            <Pressable
              onPress={onOutLogin}
              style={({ pressed }) => ({ opacity: pressed ? 0.4 : 1 })}>
              <Text fontSize="18" py="8" px="12">
                Voltar
              </Text>
            </Pressable>
          </Flex>
          <Flex align="center" pt="32" paddingBottom={2}>
            <Heading fontSize="5xl">{nameUnique}</Heading>
            <Stack w="90%" maxWidth="390px" mt="4" space="6">
              <Input
                placeholder="Digite seu e-mail"
                autoComplete="email"
                returnKeyType="next"
                defaultValue={email}
                onChangeText={emailDebounce}
                onSubmitEditing={() => passwordRef.current.focus()}
                disableFullscreenUI
              />
              <Input
                type="password"
                placeholder="Digite sua senha"
                returnKeyType="send"
                defaultValue={password}
                onChangeText={passwordDebounce}
                ref={passwordRef}
                onSubmitEditing={handleSubmit}
                disableFullscreenUI
              />
              <Button
                onPress={handleSubmit}
                bg="amber.500"
                colorScheme="amber"
                isLoading={onLogin.isLoading}
                isDisabled={!validate()}
                color="#F3F3F3"
                size="lg"
                variant="solid"
                borderRadius="9px"
                h="50px"
                shadow="9"
                fontWeight={500}>
                Entrar
              </Button>
            </Stack>
          </Flex>
        </KeyboardAvoidingView>
      </ScrollView>
    </View>
  );
}
