import { useCompanies } from '@hooks/companies/useCompanies';
import { useAuthStore } from '@stores/useAuthStore';
import useDebounce from '@utils/useDebounce';
import {
  Box,
  FlatList,
  Flex,
  HStack,
  Input,
  Skeleton,
  Stack,
  Text,
  View,
} from 'native-base';
import React, { useState } from 'react';
import { KeyboardAvoidingView, Pressable } from 'react-native';

export function SelectCompany() {
  const [nameCompany, sendName] = useState('');
  const nameDebounce = useDebounce(sendName);

  const onCompany = useAuthStore(s => s.onCompany);

  async function onSelect(name: string, nameUnique: string, companyId: string) {
    onCompany(nameUnique, companyId);
  }
  const { data, isLoading, isRefetching } = useCompanies({ name: nameCompany });

  return (
    <View flex="1">
      <Pressable>
        <KeyboardAvoidingView>
          <Flex align="center" pt="24" paddingBottom={2}>
            <Stack w="90%" maxWidth="390px" mt="10" space="7">
              <Input
                placeholder="Digite o nome da empresa"
                returnKeyType="send"
                onChangeText={nameDebounce}
                defaultValue={nameCompany}
                disableFullscreenUI
              />
              <Box>
                {(isLoading || isRefetching) && nameCompany && (
                  <Skeleton
                    h="30"
                    borderRadius="lg"
                    _light={{ endColor: 'gray.600', startColor: 'gray.400' }}
                    _dark={{ endColor: 'gray.800', startColor: 'gray.700' }}
                  />
                )}
                <FlatList
                  mt="5"
                  data={data?.data || []}
                  keyExtractor={item => item.id}
                  ItemSeparatorComponent={() => <Box h="2" />}
                  renderItem={({ item: { id, name, nameUnique } }) => (
                    <Box
                      key={id}
                      borderBottomWidth="1"
                      borderTopWidth="0"
                      borderRadius="full"
                      _dark={{ borderColor: 'gray.600' }}
                      background="amber.500"
                      pl="6"
                      pr="5"
                      py="2">
                      <Pressable
                        onPress={() => onSelect(name, nameUnique, id)}
                        style={{ width: '100%' }}>
                        <HStack
                          alignItems="center"
                          justifyContent="space-between">
                          <Text fontWeight="bold" fontSize={18}>
                            {name}
                          </Text>
                        </HStack>
                      </Pressable>
                    </Box>
                  )}
                />
              </Box>
            </Stack>
          </Flex>
        </KeyboardAvoidingView>
      </Pressable>
    </View>
  );
}
