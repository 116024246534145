import { IOrder } from '@interfaces/orders/IOrder';
import { create } from 'zustand';

type IPros = {
  newOrder: IOrder[];
  inProgress: IOrder[];
  finished: IOrder[];
  setNO(i: IOrder[]): void;
  setIP(i: IOrder[]): void;
  setFS(i: IOrder[]): void;
};

export const useOrderStore = create<IPros>(set => ({
  newOrder: [],
  inProgress: [],
  finished: [],
  setNO(i) {
    set({ newOrder: i });
  },
  setIP(i) {
    set({ inProgress: i });
  },
  setFS(i) {
    set({ finished: i });
  },
}));
